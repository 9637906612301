const translation = {
    action: {
        addNew: 'Add New',
        add: 'Add',
        create: 'Create',
        createNew: 'Create New',
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        update: 'Update',
        save: 'Save',
        saveChange: 'Save Change',
        show: 'Show',
        copy: 'Copy',
        loading: 'Loading',
        back: 'Back',
        skip: 'Skip',
        close: 'Close',
        yes: 'Yes',
        no: 'No',
        process: 'Process',
        preview: 'Preview',
        log: 'Log',
        clear: 'Clear',
        expand: 'Expand',
        info: 'Info',
        dupplicate: 'Dupplicate',
        view: 'View',
        import: 'Import'
    },
    status: {},
    message: {},
    pagination: {
        page: 'page'
    },
    text: {
        all: 'All'
    }
}

export default translation
