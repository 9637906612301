const translation = {
    pageName: 'データラベル',
    table: {
        header: {
            id: 'ID',
            name: '名前',
            description: '説明',
            connectedFlows: '接続されたフロー',
            totalCharacters: '総文字数',
            totalChunks: '総チャンク数',
            serviceAccount: 'サービスアカウント',
            createdDate: '作成日',
            updatedDate: '更新日',
            action: 'アクション',
            loader: 'ローダー',
            splitter: 'スプリッター',
            source: 'ソース',
            chunks: 'チャンク',
            chars: '文字',
            title: 'タイトル'
        },
        data: {
            notFound: 'まだドキュメントが追加されていません'
        },
        label: {
            chatFlowsUsed: '使用されたチャットフロー:'
        },
        description: '一部のファイルは処理中です。最新のステータスを取得するには更新してください。'
    },
    form: {
        search: {
            placeholder: '名前で検索'
        },
        delete: {
            title: '削除',
            description: 'ストア {{name}} を削除しますか？これにより、すべての関連するローダーとドキュメントチャンクが削除されます。'
        },
        edit: {
            title: 'データラベルを編集'
        },
        add: {
            title: '新しいデータラベルを追加',
            field: {
                serviceAccount: 'サービスアカウント',
                name: '名前',
                description: '説明'
            }
        },
        loaderList: {
            title: 'ドキュメントローダーを選択'
        },
        loaderDelete: {
            title: '削除',
            description: 'ローダー {{loaderName}} を削除しますか？これにより、すべての関連するドキュメントチャンクが削除されます。'
        },
        storeDelete: {
            title: '削除',
            description: 'ストア {{name}} を削除しますか？これにより、すべての関連するローダーとドキュメントチャンクが削除されます。'
        },
        chunkDelete: {
            title: '削除',
            description: 'チャンク {{chunkId}} を削除しますか？この操作は元に戻せません。'
        },
        importFile: {
            title: 'ファイルをインポート',
            description: 'ここにファイルをドラッグするか、クリックしてアップロード',
            note: {
                acceptableFile: '許可されるファイル形式: PDF; Word; PowerPoint; Excel',
                numberOfTime: '一度にインポートできるファイルは1つのみ',
                fileSize: '最大ファイルサイズ: 15MB',
                numberOfPage: '最大ページ数: 100ページ'
            }
        }
    },
    button: {
        manageLinks: 'リンクを管理',
        refresh: '更新',
        viewChunks: 'チャンクを表示',
        addLoader: 'ドキュメントローダーを追加',
        options: 'オプション',
        previewAndProcess: 'プレビュー＆処理',
        viewAndEditChunks: 'チャンクを表示・編集',
        delete: '削除',
        editChunk: 'チャンクを編集',
        deleteChunk: 'チャンクを削除'
    },
    action: {
        selectTextSplitter: 'テキストスプリッターを選択',
        previewChunks: 'チャンクをプレビュー'
    },
    message: {
        deletedStoreSuccess: 'ストア、ローダー、および関連するドキュメントチャンクが削除されました',
        deletedStoreFailed: 'ストアの削除に失敗しました: {{error}}',
        createdDataLabelSuccess: '新しいデータラベルが作成されました',
        createdDataLabelFailed: '新しいデータラベルの追加に失敗しました: {{error}}',
        updatedDataLabelSuccess: 'データラベルが更新されました',
        updatedDataLabelFailed: 'データラベルの更新に失敗しました: {{error}}',
        deletedLoaderSuccess: 'ローダーと関連するドキュメントチャンクが削除されました',
        deletedLoaderFailed: 'ローダーの削除に失敗しました: {{error}}',
        checkMandatoryFailed: '必須項目をすべて記入してください。',
        previewChunkFailed: 'チャンクのプレビューに失敗しました:',
        onSaveAndProcessSuccess: 'ファイルが処理のために送信されました。データラベルにリダイレクトしています...',
        onSaveAndProcessFailed: 'チャンク処理に失敗しました:',
        updatedChunkSuccess: 'ドキュメントチャンクが正常に編集されました！',
        updatedChunkFailed: 'チャンクの編集に失敗しました:',
        deletedDocChunkSuccess: 'ドキュメントチャンクが正常に削除されました！',
        deletedDocChunkFailed: 'チャンクの削除に失敗しました:',
        importProcessing: 'データのインポートが処理中です。数分お待ちください',
        importSuccess: 'ファイルが正常にインポートされました',
        importFailed: 'ファイルのインポート中にエラーが発生しました'
    },
    text: {
        characters: '文字',
        Characters: 'キャラクター',
        totalChunks: '{{currentCount}} / {{totalChunks}} チャンク',
        showChunks: '{{data}} / {{totalChunks}} チャンクを表示中',
        noChunks: 'チャンクがありません',
        note: '注:'
    },
    validation: {
        fileType: '受け入れるファイル形式は次の通りです: PDF、Word、PowerPoint、Excel',
        filePage: '100ページを超えるファイルは受け付けません',
        fileSize: '15MBを超えるファイルサイズは受け付けません'
    }
}

export default translation
