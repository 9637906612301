const translation = {
    pageName: 'Chatflows',
    table: {
        header: {
            name: 'Name',
            nodes: 'Nodes',
            lastModified: 'Last Modified Date',
            action: 'Action'
        },
        data: {
            notFound: 'No Chatflows Yet'
        }
    },
    form: {
        add: {
            placeholder: 'Untitled chatflow'
        },
        search: {
            placeholder: 'Search name or category'
        },
        conversationLog: {
            title: 'CONVERSATION LOG',
            data: {
                notFound: 'No message found'
            }
        },
        dialogueDetail: {
            title: 'DIALOGUE DETAIL',
            data: {
                notFound: 'No session found'
            }
        },
        dialogueLog: {
            title: 'DIALOGUE LOG',
            data: {
                notFound: 'No Records Found'
            }
        },
        variableDetail: {
            title: "VARIABLE'S VALUE OVER TIME"
        },
        variableLog: {
            title: 'Variable Log',
            data: {
                notFound: 'No variable found'
            }
        },
        apiDialog: {
            title: 'Embed in website or use as API'
        },
        saveChatFlow: {
            title: 'Save New Chatflow',
            field: {
                serviceAccount: 'Service Account',
                projectName: 'Project Name'
            },
            placeholder: {
                projectName: 'New Project'
            }
        },
        deleteChatFlow: {
            title: 'Delete',
            description: 'Delete chatflow {{name}}?'
        },
        renameChatFlow: {
            title: 'Rename Chatflow'
        },
        starterMessageDialog: {
            title: 'Conversation Starter Prompts',
            description: 'Starter message will show at the beginning of every conversation'
        },
        starterPrompt: {
            title: 'Conversation Starter Prompts',
            description: 'Starter prompts will only be shown when there is no messages on the chat'
        },
        chat: {
            title: 'Clear Chat History',
            description: 'Are you sure you want to clear all chat history?'
        },
        detailLog: {
            title: 'CONVERSATION LOG DETAIL'
        },
        conversationVariable: {
            title: 'CONVERSATION’S VARIABLES LOG'
        },
        chunkList: {
            title: 'Chunks Listing',
            data: {
                notFound: 'No chunks found'
            }
        },
        chunkDetail: {
            title: 'CHUNK DETAIL',
            data: {
                notFound: 'No chunk details found'
            }
        }
    },
    button: {
        saveChanges: 'Save Changes',
        variablesLog: 'Variables Log',
        downloadAll: 'Download All',
        download: 'Download',
        checkVariables: 'Check Variables',
        conversationLog: 'Conversation Log',
        detail: 'Detail',
        editName: 'Edit Name',
        saveName: 'Save Name',
        apiEndPoint: 'API Endpoint',
        saveChatFlow: 'Save Chaflow',
        settings: 'Settings',
        rename: 'Rename',
        clearChat: 'Clear Chat',
        addParameters: 'Additional Parameters',
        addNodes: 'Add Nodes'
    },
    action: {
        addNewKey: 'Add New Key',
        openNewTab: 'Open New Tab',
        makePublic: 'Make Public',
        copy: 'Copied!',
        chat: 'Chat',
        clearChatHistory: 'Clear Chat History',
        expandChat: 'Expand Chat',
        starterMessage: 'Starter Message',
        deleteChatflow: 'Delete Chatflow',
        searchNodes: 'Search nodes'
    },
    message: {
        rateLimitError: 'Need to fill all rate limit input fields',
        savedConfigSuccess: 'API Configuration Saved',
        savedConfigFailed: 'Failed to save API Configuration: {{error}}',
        savedChatBotConfigSuccess: 'Chatbot Configuration Saved',
        savedChatBotConfigFailed: 'Failed to save Chatbot Configuration: {{error}}',
        savedStarterMessageSuccess: 'Conversation Starter Message Saved',
        savedStarterMessageFailed: 'Failed to save Conversation Starter Message: {{error}}',
        savedStarterPromptSuccess: 'Conversation Starter Prompts Saved',
        savedStarterPromptFailed: 'Failed to save Conversation Starter Prompts: {{error}}',
        savedChatflowSuccess: 'Chatflow saved',
        savedChatflowFailed: 'Failed to save chatflow: {{error}}',
        deletedChatflowSuccess: 'Deleted chatflow successful',
        getChatflowFailed: 'Failed to retrieve chatflow: {{error}}',
        testChatFlowFailed: 'Test chatflow failed',
        clearedChatSuccess: 'Succesfully cleared all chat history'
    },
    text: {
        paste: 'Paste this anywhere in the {{data}} tag of your html file.',
        specific: ' You can also specify a',
        version: 'version',
        botMessage: 'Bot Message',
        userMessage: 'User Message',
        textInput: 'Text Input',
        sessionMemory: 'Session Memory',
        more: 'More',
        userId: 'User ID:',
        createdDate: 'Created Date:',
        lastUpdated: 'Last Updated:',
        time: 'Time',
        dialogueLog: 'Dialogue log',
        waitingResponse: 'Waiting for response...',
        typeQuestion: 'Type your question...',
        inputs: 'Inputs',
        output: 'Output',
        stream: 'Stream',
        session: 'Session',
        clearSearch: 'Clear Search',
        chainName: 'Chain Name:',
        searchQuery: 'Search Query:',
        chunks: 'Chunks:',
        currentStatus: 'Current Status:',
        variableName: 'Variable Name',
        value: 'Value',
        score: 'Score:'
    },
    variables: {
        userMessage: 'User',
        apiMessage: 'AI'
    },
    node: {
        form: {
            editTool: {
                title: 'Edit Tool'
            },
            editAssistant: {
                title: 'Edit Assistant'
            },
            addNewTool: {
                title: 'Add New Tool'
            },
            addNewAssistant: {
                title: 'Add New Assistant'
            },
            deleteKeyExtract: {
                confirm: 'Are you sure want to delete this key extraction?'
            }
        },
        regexTable: {
            title: 'Key Extractions',
            header: {
                no: 'No',
                defaultValue: 'Default Value',
                variableName: 'Variable Name',
                action: 'Action'
            }
        },
        output: {
            label: 'Initial Output',
            placeholder: 'Initial Output'
        },
        category: {
            Chains: 'Chains',
            'Chat Models': 'Chat Models',
            'Document Loaders': 'Document Loaders',
            Embeddings: 'Embeddings',
            Prompts: 'Prompts',
            'Text Splitters': 'Text Splitters',
            Utilities: 'Utilities',
            'Vector Stores': 'Vector Stores',
            NEW: 'NEW'
        }
    },
    validation: {
        serviceAccount: {
            required: 'Service account is required'
        },
        chatflowName: {
            required: 'Project name is required'
        }
    }
}

export default translation
